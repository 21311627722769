<template>
  <div class="exam--detail">
    <div class="tit">{{ Title }}</div>
    <div class="tit--sub">
      <span>{{ CustomerName }}</span>
      <span>
        <img src="../../../assets/images/weixin.png" alt />
        <img src="../../../assets/images/weibo.png" alt />
      </span>
    </div>
    <div class="tit--cont" v-show="type == '1'" v-html="contentDom"></div>
    <div class="jgh-cont" v-show="type == '2'">
      <div v-html="contentDom"></div>

      <div class="jgh-cont-carousel">
        <swiper ref="swiper1" :options="swiperOptions">
          <swiper-slide v-for="item in imgList" :key="item">
            <img width="100%" :src="item" />
          </swiper-slide>
          <!-- pagination  -->
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      contentDom: '',
      Title: '',
      Subtitle: '',
      CustomerName: '',
      type: '',
      imgList: [],
      swiperOptions: {
        init: false,
        keyboard: true,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3000
        },
        pagination: {
          el: '.swiper-pagination'
        }
      }
    };
  },
  mounted() {
    this.getContent();
  },
  computed: {
    swiper() {
      return this.$refs.swiper1.swiper;
    }
  },
  methods: {
    getContent() {
      let Id = this.$route.query.id || '';
      this.type = this.$route.query.type || '';
      if (Id) {
        axios.get(`/api/Cases?Id=${Id}`).then(res => {
          if (res.data.Success) {
            let data = res.data.Data;
            let ImgAddress = JSON.parse(data.ImgAddress);
            this.contentDom = this.type == '1' ? data.MainBody : data.Describe;
            this.Title = data.Title;
            this.Subtitle = data.Subtitle;
            this.CustomerName = data.CustomerName;
            for (const key in ImgAddress) {
              this.imgList.push(ImgAddress[key]);
            }
            this.$nextTick(() => {
              this.swiper.init();
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$bdColor: #464646;

.exam--detail {
  padding: 0 15px;
  border-top: 1px solid $bdColor;

  .tit {
    padding-top: 25px;
    line-height: 28px;
    font-size: 18px;

    &--sub {
      display: flex;
      padding-top: 10px;
      padding-bottom: 12px;
      font-size: 14px;
      border-bottom: 1px solid $bdColor;
      color: #999;
      justify-content: space-between;

      img {
        height: 10px;
        & + img {
          margin-left: 10px;
        }
      }
    }

    &--cont {
      font-size: 10px;
      color: #999999;
    }
  }
  .jgh-cont {
    padding: 10px 0;
    font-size: 10px;
    color: #999999;
  }
  .jgh-cont-carousel {
    padding-top: 12%;
  }

  @media (min-width: 768px) {
    padding: 0 30px;

    .tit {
      padding-top: 50px;
      line-height: 56px;
      font-size: 36px;

      &--sub {
        padding-top: 20px;
        padding-bottom: 24px;
        font-size: 26px;
        img {
          height: 20px;
          & + img {
            margin-left: 20px;
          }
        }
      }

      &--cont {
        font-size: 26px;
      }
    }

    .jgh-cont {
      padding: 20px 0;
      font-size: 26px;
    }
  }
}
</style>
